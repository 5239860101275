export default class GalleryModal {
    constructor() {
        this.activeGallery = null; // Track the currently active gallery
        this.init();
    }

    init() {
        const galleries = document.querySelectorAll("#gallery");
        galleries.forEach((gallery) => {
            const openModal = gallery.querySelector("#galleryOpen");

            if (!openModal) {
                console.warn("No openModal button found in gallery.");
                return;
            }

            openModal.addEventListener("click", () => this.toggleFixedClass(gallery, openModal));
        });

        // Listen for the ESC key globally
        document.addEventListener("keydown", (event) => this.handleKeyDown(event));
    }

    toggleFixedClass(galleryDiv, openModal) {
        const isActive = galleryDiv.classList.toggle("gallery-open"); // Toggle the class
        this.updateButtonContent(openModal, isActive);
        document.body.classList.toggle("overflow-hidden", isActive);

        // Track the active gallery
        this.activeGallery = isActive ? galleryDiv : null;
    }

    updateButtonContent(openModal, isActive) {
        if (isActive) {
            openModal.innerHTML = `
                <svg class="w-[18px] h-[18px] xl:w-[20px] xl:h-[20px] {{ color }}" viewbox="0 0 256 256">
                    <rect width="256" height="256" fill="none"/><line x1="40" y1="128" x2="216" y2="128" fill="none" stroke="currentColor" stroke-linecap="butt" stroke-linejoin="butt" stroke-width="28"/>
                </svg>
            `;
        } else {
            openModal.innerHTML = `
                <svg class="w-[18px] h-[18px] xl:w-[20px] xl:h-[20px] {{ color }}" viewbox="0 0 256 256">
                    <rect width="256" height="256" fill="none"/><line x1="40" y1="128" x2="216" y2="128" fill="none" stroke="currentColor" stroke-linecap="butt" stroke-linejoin="butt" stroke-width="28"/>
                    <line x1="128" y1="40" x2="128" y2="216" fill="none" stroke="currentColor" stroke-linecap="butt" stroke-linejoin="butt" stroke-width="28"/>
                </svg>
            `;
        }
    }

    handleKeyDown(event) {
        if (event.key === "Escape" && this.activeGallery) {
            // Close the active gallery
            const openModal = this.activeGallery.querySelector("#galleryOpen");
            if (openModal) {
                this.toggleFixedClass(this.activeGallery, openModal);
            }
        }
    }
}
