import '@/styles/index.scss';

import Menu from '@/app/components/Menu';
import HoverEffect from '@/app/components/ListMouseover';
import ScrollHeader from '@/app/components/Header';
import ScrollFadeBackground from '@/app/components/ScrollFadeBackground';
import BackToTop from '@/app/components/ToTop';
import MirroredSplide from '@/app/components/Slider';
import GradientDiv from '@/app/components/GradientCanvas';
import MirrorVisibility from '@/app/components/MirrorVisibility';
import GalleryCarousel from '@/app/components/Gallery';
import GalleryModal from '@/app/components/GalleryModal';
import DigitalMuseumSearch from '@/app/components/DigitalMuseumSearch';

import 'lazysizes';

import Swup from "swup";
import SwupBodyClassPlugin from "@swup/body-class-plugin";
import SwupScriptsPlugin from "@swup/scripts-plugin";

const options = {
	containers: ['#fade'],
	plugins: [new SwupBodyClassPlugin(), new SwupScriptsPlugin()],
};

const swup = new Swup(options);

const NNKM = {
	init() {
		if (document.querySelector('#backToTop')) {
			new BackToTop('backToTop');
		}

		if (document.querySelector('#menuButton')) {
			new Menu('menuButton', 'morphSVG', 'morphPath', 'menu', 'logo');
		}

		if (document.querySelector('.list')) {
			new HoverEffect('.list');
		}

		if (document.querySelector('header div')) {
			new ScrollHeader('header div');
		}

		if (document.querySelector('footer')) {
			new ScrollFadeBackground(500);
		}

		if (document.querySelector('.gallery')) {
			new GalleryCarousel('.gallery');
		}

		if (document.getElementById("gallery")) {
			new GalleryModal();
		}

		if (document.querySelector('#exhibition-gradient')) {
			new MirrorVisibility('#exhibition-gradient', '.gradient-image', 'is-hidden');
		}

		if (document.querySelector('.gradient-div') && document.querySelector('.gradient-image')) {
			new GradientDiv('.gradient-div', '.gradient-image');
		}

		if (document.querySelector('#exhibition-image')) {
			new MirroredSplide('#exhibition-image', ['#exhibition-content', '#exhibition-gradient'], {
				type: 'loop',
				drag: true,
				perPage: 1,
				autoplay: true,
				interval: 5000,
				speed: 750,
				pagination: false,
				arrows: false,
				focus: 'center',
				padding: { left: '18%', right: '18%' },
				breakpoints: {
					768: {
						padding: { left: '0', right: '0' },
					}
				},
			});
		}

		if (document.getElementById('search-results')) {
			new DigitalMuseumSearch('#search-form', '#search-results');
		}
	},

	initOnLoad() {
		// Add any load-based initializations here 
	}
};

document.addEventListener("DOMContentLoaded", () => NNKM.init());
window.addEventListener("load", () => NNKM.initOnLoad());
swup.hooks.on("page:view", () => NNKM.init());

console.log('Developed by ANTI - https://anti.as/');