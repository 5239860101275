import Splide from '@splidejs/splide';

export default class GalleryCarousel {
    constructor(selector = '.gallery') {
        this.selector = selector;
        this.keyboardListener = null;
        this.init();
    }

    init() {
        const splideElements = document.querySelectorAll(this.selector);

        splideElements.forEach((splideElement) => {
            const splideOptions = this.getSplideOptions();
            const splide = new Splide(splideElement, splideOptions);

            splideElement.addEventListener('focusin', () => {
                this.addKeyboardNavigation(splide);
            });

            splideElement.addEventListener('focusout', () => {
                this.removeKeyboardNavigation();
            });

            splide.mount();
        });
    }

    getSplideOptions() {
        return {
            type: 'fade',
            drag: true,
            pagination: false,
            speed: 200,
        };
    }

    addKeyboardNavigation(splide) {
        if (!this.keyboardListener) {
            this.keyboardListener = (event) => {
                if (event.key === 'ArrowLeft') {
                    splide.go('<');
                } else if (event.key === 'ArrowRight') {
                    splide.go('>');
                }
            };
            document.addEventListener('keydown', this.keyboardListener);
        }
    }

    removeKeyboardNavigation() {
        if (this.keyboardListener) {
            document.removeEventListener('keydown', this.keyboardListener);
            this.keyboardListener = null;
        }
    }
}
