export default class DigitalMuseumSearch {
    constructor(formSelector, resultsSelector) {
        this.form = document.querySelector(formSelector);
        this.resultsDiv = document.querySelector(resultsSelector);
        this.apiKey = 'demo';
        this.apiEndpoint = 'https://api.dimu.org/api/solr/select';

        this.init();
    }

    init() {
        this.form.addEventListener('submit', (event) => this.handleSubmit(event));
    }

    handleSubmit(event) {
        event.preventDefault();
        const queryInput = this.form.querySelector('input[name="query"]');
        if (!queryInput) {
            console.error('Search input field not found');
            return;
        }

        const query = queryInput.value.trim();
        if (query) {
            this.search(query);
        } else {
            this.displayMessage('Please enter a search term.');
        }
    }

    async search(query) {
        this.clearResults();

        const url = this.buildApiUrl(query);

        try {
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const data = await response.json();

            if (data.response?.docs?.length > 0) {
                this.displayResults(data.response.docs);
            } else {
                this.displayMessage('No results found.');
            }
        } catch (error) {
            this.displayMessage(`An error occurred: ${error.message}`);
        }
    }

    buildApiUrl(query) {
        const fields = [
            'artifact.ingress.title',
            'artifact.ingress.producer',
            'artifact.defaultMediaIdentifier',
            'artifact.ingress.production.fromYear',
            'artifact.uniqueId'
        ];
        return `${this.apiEndpoint}?q=${encodeURIComponent(query)}&wt=json&fq=identifier.owner:NNKM&fl=${fields.join(',')}&api.key=${this.apiKey}`;
    }

    displayResults(results) {
        this.resultsDiv.innerHTML = '';
        results.forEach(result => this.renderResult(result));
    }

    renderResult(result) {
        const title = result['artifact.ingress.title'] || '';
        const artist = result['artifact.ingress.producer'] || ' ';
        const fromYear = result['artifact.ingress.production.fromYear'] || '';
        const mediaId = result['artifact.defaultMediaIdentifier'] || null;
        const entryId = result['artifact.uniqueId'] || null;

        const imageUrl = mediaId ? `https://dms01.dimu.org/image/${mediaId}?dimension=600x600` : null;
        const entryUrl = entryId ? `https://digitaltmuseum.org/${entryId}` : null;

        if (imageUrl && entryUrl) {
            const resultDiv = document.createElement('div');
            resultDiv.classList.add('col-span-12', 'md:col-span-3', 'result', 'group');
            resultDiv.innerHTML = `
                <a href="${entryUrl}" target="_blank">
                    <img src="${imageUrl}" alt="${title}" class="w-full h-auto object-fill mb-8 md:mb-12">
                    <div class="flex gap-x-24 md:gap-x-40 mb-4 md:mb-8">
                        <div class="font-tango-bold-italic text-3xs md:text-2xs">${artist}</div>
                        <div class="font-tango-bold text-3xs md:text-2xs">${fromYear}</div>
                    </div>
                    <h3 class="font-tango-bold text-2xs md:text-sm xl:text-base group-hover:translate-x-16 transition-transform duration-200">${title}</h3>
                </a>
            `;
            this.resultsDiv.appendChild(resultDiv);
        }
    }

    displayMessage(message) {
        this.resultsDiv.innerHTML = `
            <div class="col-span-3">
                <div class="font-tango-bold text-base">${message}</div>
            </div>
        `;
    }

    clearResults() {
        this.resultsDiv.innerHTML = '';
    }
}
