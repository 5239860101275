export default class BackToTop {
	constructor(buttonId) {
		this.button = document.getElementById(buttonId);

		if (!this.button) return;

		this.button.style.display = 'none';
		this.init();
	}

	init() {
		window.addEventListener('scroll', () => this.toggleButtonVisibility());
		this.button.addEventListener('click', () => this.scrollToTop());
	}

	toggleButtonVisibility() {
		if (window.scrollY > 200) {
			this.button.style.display = 'block';
		} else {
			this.button.style.display = 'none';
		}
	}

	scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}
}
