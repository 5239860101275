export default class ScrollFadeBackground {
	constructor(offset = 500) {
		this.hasFadedToBlack = false;
		this.offset = offset;

		this.init();
	}

	init() {
		window.addEventListener('scroll', () => this.checkScrollPosition());
	}

	checkScrollPosition() {
		const scrollTop = window.scrollY;
		const documentHeight = document.documentElement.scrollHeight;
		const windowHeight = window.innerHeight;

		const distanceFromBottom = documentHeight - (scrollTop + windowHeight);

		if (distanceFromBottom <= this.offset && !this.hasFadedToBlack) {
			this.fadeToBlack();
			this.hasFadedToBlack = true;
		} else if (distanceFromBottom > this.offset && this.hasFadedToBlack) {
			this.resetBackground();
			this.hasFadedToBlack = false;
		}
	}

	fadeToBlack() {
		document.body.classList.add('bg-black')
	}

	resetBackground() {
		document.body.classList.remove('bg-black')
	}
}
