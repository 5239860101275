import { gsap } from 'gsap';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';
import { CustomEase } from "gsap/CustomEase";

gsap.registerPlugin(MorphSVGPlugin);
gsap.registerPlugin(CustomEase)

export default class Menu {
	constructor(menuButtonId, morphSVGId, morphPathId, menuId, logoId) {
		this.menuButton = document.getElementById(menuButtonId);
		this.morphSVG = document.getElementById(morphSVGId);
		this.morphPath = document.getElementById(morphPathId);
		this.menu = document.getElementById(menuId);
		this.logo = document.getElementById(logoId);
		this.body = document.querySelector('body');

		this.isOpen = false;
		this.isToggled = false;

		this.init();
	}

	init() {
		if (!this.menuButton) return;

		this.menuButton.addEventListener('click', () => this.toggleMenu());
	}

	toggleMenu() {
		const tl = gsap.timeline();

		if (this.isOpen) {
			tl.to(
				this.morphSVG,
				{
					duration: 0.5,
					delay: 0.5,
					width: this.isMobileViewport() ? '32px' : '53px',
					height: this.isMobileViewport() ? '15px' : '23px',
					top: this.isMobileViewport() ? '12px' : '24px',
					ease: CustomEase.create("custom", "M0,0 C0.65,0.05 0.36,1 1,1 ")
				},
				0
			)
				.to(
					this.morphPath,
					{
						duration: 0.5,
						morphSVG:
							'M89 0C86.2828 22.4107 67.1943 39.7895 44.4995 39.9996C44.6377 39.9996 44.3614 40.0005 44.4995 39.9996C21.6175 39.9996 2.73355 22.5477 0 0H89Z',
						ease: CustomEase.create("custom", "M0,0 C0.65,0.05 0.36,1 1,1 ")
					},
					0
				)

				.to(
					this.logo,
					{
						duration: 0.5,
						fill: 'black',
						ease: CustomEase.create("custom", "M0,0 C0.65,0.05 0.36,1 1,1 ")
					},
					0
				)

				.to(
					this.menuButton,
					{
						duration: 0.5,
						color: 'black',
						ease: CustomEase.create("custom", "M0,0 C0.65,0.05 0.36,1 1,1 ")
					},
					0
				)

				.to(
					this.menu,
					{
						duration: 0.5,
						autoAlpha: 0,
						ease: CustomEase.create("custom", "M0,0 C0.65,0.05 0.36,1 1,1 ")
					},
					0
				);
		} else {
			tl.to(
				this.morphSVG,
				{
					duration: 0.5,
					width: '101%',
					height: '101vh',
					top: '0',
					ease: CustomEase.create("custom", "M0,0 C0.65,0.05 0.36,1 1,1 ")
				},
				0
			)
				.to(
					this.morphPath,
					{
						duration: 0.5,
						delay: 0.5,
						morphSVG: 'M0 0H89V40H0V0Z',
						ease: CustomEase.create("custom", "M0,0 C0.65,0.05 0.36,1 1,1 ")
					},
					0
				)

				.to(
					this.logo,
					{
						duration: 0.5,
						delay: 0.5,
						fill: 'white',
						ease: CustomEase.create("custom", "M0,0 C0.65,0.05 0.36,1 1,1 ")
					},
					0
				)

				.to(
					this.menuButton,
					{
						duration: 0.5,
						delay: 0.5,
						color: 'white',
						ease: CustomEase.create("custom", "M0,0 C0.65,0.05 0.36,1 1,1 ")
					},
					0
				)

				.to(
					this.menu,
					{
						duration: 0.5,
						delay: 0.5,
						autoAlpha: 1,
						ease: CustomEase.create("custom", "M0,0 C0.65,0.05 0.36,1 1,1 ")
					},
					0
				);
		}

		this.isOpen = !this.isOpen;
		this.body.classList.toggle('!overflow-hidden');

		this.menuButton.setAttribute('aria-expanded', this.isOpen);

		this.toggleButtonText();
	}

	toggleButtonText() {
		const menuTextSpan = this.menuButton.querySelector('span:nth-child(1)'); // The "Meny" span
		const closeTextSpan = this.menuButton.querySelector('span:nth-child(2)'); // The "Lukk" span
	
		if (this.isToggled) {
			menuTextSpan.style.display = 'inline';
			closeTextSpan.style.display = 'none';
			this.menuButton.setAttribute("aria-label", "Open menu");
			this.menu.setAttribute("aria-hidden", "true");
		} else {
			menuTextSpan.style.display = 'none';
			closeTextSpan.style.display = 'inline';
			this.menuButton.setAttribute("aria-label", "Close menu");
			this.menu.setAttribute("aria-hidden", "false");
		}
	
		this.isToggled = !this.isToggled;
	}
	

	isMobileViewport() {
		return window.matchMedia("(max-width: 768px)").matches;
	}
}
