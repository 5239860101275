export default class GradientDiv {
    constructor(gradientDivSelector, imageSelector) {
        this.gradientDivs = document.querySelectorAll(gradientDivSelector);
        this.imageElements = document.querySelectorAll(imageSelector);

        if (this.gradientDivs.length && this.imageElements.length) {
            this.init();
        } else {
            console.error('Gradient divs or image elements not found.');
        }
    }

    init() {
        this.imageElements.forEach((image, index) => {
            if (image.complete && image.naturalWidth !== 0) {
                this.updateGradient(this.gradientDivs[index], image);
            } else {
                image.onload = () => this.updateGradient(this.gradientDivs[index], image);
                image.src = image.src;
            }
        });
    }

    updateGradient(gradientDiv, imageElement) {
        if (!imageElement.naturalWidth || imageElement.naturalWidth === 0) {
            console.error('Image dimensions not available.');
            return;
        }

        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');

        tempCanvas.width = imageElement.naturalWidth;
        tempCanvas.height = imageElement.naturalHeight;

        tempCtx.drawImage(imageElement, 0, 0);

        try {
            const imageData = tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height).data;
            const prominentColors = this.getProminentSaturatedColors(imageData, 4);
            this.applyGradientCSS(gradientDiv, prominentColors);
        } catch (error) {
            console.error('Error accessing image data:', error);
        }
    }

    applyGradientCSS(gradientDiv, colors) {
        let step1 = 0, step2 = 10, step3 = 30, step4 = 60;
        let direction1 = -1, direction2 = 1, direction3 = -1, direction4 = 1;
        const speed1 = 0.01, speed2 = 0.015, speed3 = 0.008, speed4 = 0.012;

        const animate = () => {
            const defaultColor = { r: 0, g: 0, b: 0 }; // Default black color

            const c1 = colors[1] || defaultColor;
            const c2 = colors[2] || defaultColor;
            const c3 = colors[3] || defaultColor;

            step1 += direction1 * speed1;
            step2 += direction2 * speed2;
            step3 += direction3 * speed3;
            step4 += direction4 * speed4;

            if (step1 > 5 || step1 < 0) direction1 *= -1;
            if (step2 > 15 || step2 < 5) direction2 *= -1;
            if (step3 > 35 || step3 < 25) direction3 *= -1;
            if (step4 > 65 || step4 < 55) direction4 *= -1;

            const colorStops = [
                `rgba(${c1.r}, ${c1.g}, ${c1.b}, 1) ${step1.toFixed(2)}%`,
                `rgba(${c2.r}, ${c2.g}, ${c2.b}, 0.9) ${step2.toFixed(2)}%`,
                `rgba(${c3.r}, ${c3.g}, ${c3.b}, 0.7) ${step3.toFixed(2)}%`,
                `rgba(255, 255, 255, 0) ${step4.toFixed(2)}%`
            ];

            gradientDiv.style.background = `radial-gradient(circle at 50% 0%, ${colorStops.join(', ')})`;

            requestAnimationFrame(animate);
        };

        animate();
    }

    getProminentSaturatedColors(imageData, count) {
        const colorMap = {};

        for (let i = 0; i < imageData.length; i += 4) {
            const r = imageData[i];
            const g = imageData[i + 1];
            const b = imageData[i + 2];

            const max = Math.max(r, g, b);
            const min = Math.min(r, g, b);
            const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
            const saturation = (max - min) / max || 0;

            if (brightness > 50 && saturation > 0.5) {
                const key = `${r},${g},${b}`;
                colorMap[key] = (colorMap[key] || 0) + 1;
            }
        }

        let colors = Object.entries(colorMap)
            .map(([color]) => {
                const [r, g, b] = color.split(',').map(Number);
                return { r, g, b, brightness: 0.299 * r + 0.587 * g + 0.114 * b };
            })
            .sort((a, b) => a.brightness - b.brightness)
            .slice(0, count);

        while (colors.length < count) {
            colors.push({ r: 0, g: 0, b: 0 });
        }

        return colors;
    }

}
