export default class ScrollFade {
    constructor(elementSelector) {
        this.element = document.querySelector(elementSelector);

        if (!this.element) {
            console.error('Element not found.');
            return;
        }

        this.init();
    }

    init() {
        this.handleScroll = this.throttle(() => this.updateOpacity(), 100);
        window.addEventListener('scroll', this.handleScroll);
        this.updateOpacity();
    }

    updateOpacity() {
        const scrollPosition = window.scrollY;
        const fadeDistance = window.innerHeight;

        let opacity = 1 - Math.min(scrollPosition / fadeDistance, 1);
        this.element.style.opacity = opacity.toFixed(3);
    }

    throttle(callback, limit) {
        let ticking = false;
        return () => {
            if (!ticking) {
                requestAnimationFrame(() => {
                    callback();
                    ticking = false;
                });
                ticking = true;
            }
        };
    }
}
