export default class ScrollHeader {
	constructor(headerSelector) {
		this.lastScrollY = window.scrollY;
		this.header = document.querySelector(headerSelector);

		if (this.header) {
			this.init();
		}
	}

	init() {
		window.addEventListener('scroll', () => this.handleScroll());
	}

	handleScroll() {
		const viewportHeight = window.innerHeight;
		const totalHeight = document.documentElement.scrollHeight;
		const currentScrollBottom = window.scrollY + viewportHeight;

		const isNearBottom = currentScrollBottom >= totalHeight - viewportHeight;

		if (isNearBottom) {
			this.header.classList.add('-translate-y-full');
		} else if (window.scrollY < 100) {
			this.header.classList.remove('-translate-y-full');
		} else if (window.scrollY < this.lastScrollY) {
			this.header.classList.remove('-translate-y-full');
		} else {
			this.header.classList.add('-translate-y-full');
		}

		this.lastScrollY = window.scrollY;
	}
}
