import Splide from '@splidejs/splide';
import '@splidejs/splide/css';

export default class MirroredSplide {
    constructor(mainSelector, mirrorSelectors, options = {}) {
        this.mainSplide = new Splide(mainSelector, options);
        this.mirrorSelectors = mirrorSelectors;
        this.mirrorSlides = [];
        this.keyboardBindings = options.keyboard || {
            ArrowLeft: 'prev',
            ArrowRight: 'next',
        };

        this.initialize();
        this.addKeyboardNavigation();
        this.addPageVisibilityListener();
    }


    initialize() {
        this.mainSplide.mount();

        this.mirrorSlides = this.mirrorSelectors.map(selector =>
            Array.from(document.querySelectorAll(`${selector} .mirror-slide`))
        );

        this.mainSplide.on('move', newIndex => this.updateMirrors(newIndex));

        this.updateMirrors(this.mainSplide.index);
    }

    addPageVisibilityListener() {
        this.handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                this.updateMirrors(this.mainSplide.index);
            }
        };

        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }

    removePageVisibilityListener() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }

    updateMirrors(activeIndex) {
        this.mirrorSlides.flat().forEach(slide => {
            slide.classList.remove('active');
        });

        this.mirrorSlides.forEach(mirrors => {
            if (mirrors[activeIndex]) {
                mirrors[activeIndex].classList.add('active');
            }
        });
    }


    addKeyboardNavigation() {
        document.addEventListener('keydown', event => {
            const action = this.keyboardBindings[event.key];

            if (action) {
                if (action === 'prev') {
                    this.mainSplide.go('<');
                } else if (action === 'next') {
                    this.mainSplide.go('>');
                }
                event.preventDefault();
            }
        });
    }
}
