export default class HoverEffect {
	constructor(groupSelector) {
		this.groups = document.querySelectorAll(groupSelector);

		this.init();
	}

	init() {
		this.groups.forEach(group => {
			const followImage = group.querySelector('figure');

			group.addEventListener('mouseenter', () => this.showImage(followImage));
			group.addEventListener('mousemove', event =>
				this.moveImage(event, group, followImage)
			);
			group.addEventListener('mouseleave', () => this.hideImage(followImage));
		});
	}

	showImage(followImage) {
		followImage.classList.add('!opacity-100');
	}

	moveImage(event, group, followImage) {
		const rect = group.getBoundingClientRect();
		const imageWidth = followImage.offsetWidth;
		const imageHeight = followImage.offsetHeight;

		followImage.style.left = `${event.clientX - rect.left - imageWidth / 2}px`;
		followImage.style.top = `${event.clientY - rect.top - imageHeight / 2}px`;
	}

	hideImage(followImage) {
		followImage.classList.remove('!opacity-100');
	}
}
